@import "../../assets/scss/colors.scss";
body.light-theme{
  .label {
    background: #111;
    display: flex;
    border-radius: 50px;
    height: 26px;
    width: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:5px;
  }
  
}
body.dark-theme{
.label {
  background: #fff;
  display: flex;
  border-radius: 50px;
  height: 26px;
  width: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:5px;
  border: 1px solid #000;
}
@media (max-width: 800px)  {
  .label {
    border: 1px solid #000;
  }
  
}
}
.ball {
  border-radius: 50%;
  background: $yellow-dark; /* i am using scss to declare global variables here  */
  position: absolute;
  height: 22px;
  width: 22px;
  // top:2px;
  // bottom:2px;
  left:2px;
  transition: transform 0.2s linear;
}
.fa-moon {
  color: #f1c40f;
 
}
.fa-sun {
  color: $yellow-dark;
}
.checkbox{
  opacity: 0;
  position:absolute
}

.checkbox:checked + .label .ball{
  transform: translate(24px);
}


