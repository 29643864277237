$body-light-background:  #F5F7FD;
$yellow-dark:     #FFE001;
$yellow-light: #FFFBDE;
$light:         #E7F1EB;
//$dark:          #121212;
$dark:          #313231;
$danger:        #dc3545;
$text-color:    #4B4C4F;

$menu-normal: #A4ADC8;
$menu-active: #0E0E0E;
$menu-header: #0F1B41;
$text-fade-color: #A2A2A2;
// buttons
$primary-text-color: #0E0E0E;
$success-color:    #DCFAF1;
$success-color-dark: #14A277;
$btn-primary-box-shadow: #FFC107;

//walkthrough carousel dots
$carousel-in-active: #D7DADE;
$carousel-active: $yellow-dark; 

$slider-bg: #F6F6F6;
$slider-bg-active: $yellow-dark;

$discussion-action-color: #26292D;
$discussion-time: #63666A;


$dark-mode-card: #0F1635;
$dark-mode-bg:#080E29;
