@import "assets/scss/reset";
@import "assets/scss/custom-bootstrap";
@import "assets/scss/darkmode.scss";
html {
  color: $text-color;
  font-family: "Inter", sans-serif;
  font-size: 100%;
}

html,
body,
.fill-parent {
  @include fill-parent;
}

body {
  background-color: $body-light-background !important;
  font-weight: 500;
  color: $text-color;
  font-size: 16px;
}

.min-content-height {
  min-height: $minMainContentHeight;
}
.min-content-height-footer {
  min-height: $minMainContentHeightFooter;
}
hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.text-black {
  color: rgba(0, 0, 0, 0.8) !important;
}

.header-padding {
  padding-top: $headerHeight !important;
}

@media screen and (min-width: 600px) {
  html {
    font-size: 75%;
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 87.5%;
  }
}

@media screen and (min-width: 1500px) {
  html {
    font-size: 100%;
  }
}

@media screen and (min-width: 2000px) {
  html {
    font-size: 112.5%;
  }
}

@media screen and (min-width: 2500px) {
  html {
    font-size: 125%;
  }
}
