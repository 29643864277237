@import "../../assets/scss/colors.scss";
body.dark-theme{
    .calendar {
        background: $dark-mode-bg;
        .eventHeader {
            font-family: "SF Pro Display";
            font-style: normal;
            font-weight: normal;
            font-size: 1.1875rem;
            line-height: 23px;
            letter-spacing: 0.437312px;
            color: #fff;
            div {
                p {
                    small {
                        font-family: "SF Pro Display";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 0.9375rem;
                        line-height: 26px;
    
                        /* identical to box height, or 171% */
                        letter-spacing: 0.437312px;
    
                        color: #fff;
                    }
                }
                a {
                    font-family: "SF Pro Display";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.8125rem;
                    line-height: 16px;
                    letter-spacing: 0.874624px;
                    text-transform: uppercase;
                    color: #fff;
                }
            }
        }
        .single-event-card {
            .event-title {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 0.9375rem;
                line-height: 1.1875rem;
                color: #171725;
            }
            .event-desc {
                font-family: "SF Pro Display";
                font-style: normal;
                font-weight: normal;
                font-size: 0.9375rem;
                line-height: 1.125rem;
                letter-spacing: 0.437312px;
                color: #919ba8;
            }
            .seeAll {
                font-family: SF Pro Display;
                font-style: normal;
                font-weight: 600;
                font-size: 0.8125rem;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.437312px;
                // color: #fff;
            }
        }
    }
    .eventBody {
        background: $dark-mode-bg;
        border: 1px solid $dark-mode-bg;
        box-sizing: border-box;
        border-radius: 9.0625px;
        padding: 5px 6px;
    }
    .eventBody b {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12.6875px;
        line-height: 15px;
        letter-spacing: 0.090625px;
    
        color: #fff;
    }
    .eventTime {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 0.625rem;
        line-height: 13px;
    
        /* identical to box height */
        text-align: right;
    
        color: #fff;
    }
    .eventButton {
        font-size: 0.75rem !important;
        margin-bottom: 10px;
    }
    .seeAll {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 15px;
        text-decoration: none;
    }
}
body.light-theme{
    .calendar {
        background: #fff;

        .eventHeader {
            font-family: "SF Pro Display";
            font-style: normal;
            font-weight: normal;
            font-size: 1.1875rem;
            line-height: 23px;
            letter-spacing: 0.437312px;
            color: #171725;
            div {
                p {
                    small {
                        font-family: "SF Pro Display";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 0.9375rem;
                        line-height: 26px;
    
                        /* identical to box height, or 171% */
                        letter-spacing: 0.437312px;
    
                        color: #92929d;
                    }
                }
                a {
                    font-family: "SF Pro Display";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.8125rem;
                    line-height: 16px;
                    letter-spacing: 0.874624px;
                    text-transform: uppercase;
                    color: #4b4c4f;
                }
            }
        }
        .single-event-card {
            .event-title {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 0.9375rem;
                line-height: 1.1875rem;
                color: #171725;
            }
            .event-desc {
                font-family: "SF Pro Display";
                font-style: normal;
                font-weight: normal;
                font-size: 0.9375rem;
                line-height: 1.125rem;
                letter-spacing: 0.437312px;
                color: #919ba8;
            }
            .seeAll {
                font-family: SF Pro Display;
                font-style: normal;
                font-weight: 600;
                font-size: 0.8125rem;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.437312px;
                // color: #63666a;
            }
        }
    }
    .eventBody {
        background: #ffffff;
        border: 1px solid #ebecee;
        box-sizing: border-box;
        border-radius: 9.0625px;
        padding: 5px 6px;
    }
    .eventBody b {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12.6875px;
        line-height: 15px;
        letter-spacing: 0.090625px;
    
        color: #171725;
    }
    .eventTime {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 0.625rem;
        line-height: 13px;
    
        /* identical to box height */
        text-align: right;
    
        color: #92929d;
    }
    .eventButton {
        font-size: 0.75rem !important;
        margin-bottom: 10px;
    }
    .seeAll {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 15px;
        text-decoration: none;
    }
}



