@import "../../assets/scss/colors.scss";
body.dark-theme{
  .hr{
    background: $yellow-dark;
  }
  .pageTitle {
    color: #fff;
    line-height: 24px;
    font-size: 1rem;
  }
  .tabTitle {
    cursor: pointer;
    &.hasHr{
      padding-bottom: 1rem;
      border-bottom: 2px solid  $yellow-dark !important;
    }
    &.noHr{
      padding-bottom: 1rem;
      border-bottom: 2px solid transparent !important;
    }
  }
  .font-12 {
    font-size: 0.75rem;
  }
  .greyText {
    color: #fff;
    font-weight: 500 !important;
  }
  
}
body.light-theme{
  .hr{
    background: #0E0E0E;
  }
  .pageTitle {
    color: $menu-header;
    line-height: 24px;
    font-size: 1rem;
  }
  .tabTitle {
    cursor: pointer;
    
    &.hasHr{
      padding-bottom: 1rem;
      border-bottom: 2px solid #0E0E0E !important;
    }
    &.noHr{
      padding-bottom: 1rem;
      border-bottom: 2px solid transparent !important;
    }
  }
  .font-12 {
    font-size: 0.75rem;
  }
  .greyText {
    color: $text-color;
    font-weight: 500 !important;
  }
  
  
}
@media (min-width: 300px) and (max-width: 1024px){
  .pageTitle {
    line-height: 24px;
    &.hasHr{
      padding-bottom: 2rem !important;
      
    }
    &.noHr{
      padding-bottom: 2rem !important;;
     
    }
  }
 
  .pageTitle hr{
    background: none;
  }
  .tabTitle {
    padding-top:10px;
    height: 20px;
    margin-bottom: 0px;
  }
  .tabTitle hr {
    margin-top: 6.5px;margin-bottom: 5px;
    border-width: 3px;
  }
}
