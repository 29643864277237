@import '../../assets/scss/colors.scss';

body.dark-theme{
  .select-holder select {
    
    padding: 0px 35px 0px 5px;
    border:none;
    height: 42px;
    outline:none;
    appearance: none; 
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #fff;
    background: {
        color: $dark-mode-bg;
        
        image: url("../../assets/img/course/select-dropdown-dark.png") ;
        position: right;
        repeat: no-repeat;
      }
  }
  .select-holder select::-ms-expand {
    display: none;
}
  .select-selected {
    background-color: #fff;
  }
  .select-holder{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background:$dark-mode-bg;
      border: 0.68px solid #F2F2F2;
  }
  .select-holder>span{
    color: #f6f6f6;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 100;
    letter-spacing: 0.325px;
   
  }
  
}
body.light-theme{
  .select-holder select {
    
    padding: 0px 35px 0px 5px;
    border:none;
    height: 42px;
    outline:none;
    appearance: none; 
    font-size: 0.875rem;
    line-height: 1.125rem;
    background: {
        color: #fff;
        image: url("../../assets/img/course/select-dropdown.png");
        position: right;
        repeat: no-repeat;
      }
  }
  .select-holder select::-ms-expand {
    display: none;
}
  .select-selected {
    background-color: #fff;
  }
  .select-holder{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background:#fff;
      border: 0.68px solid #F2F2F2;
  }
  .select-holder>span{
    color: $text-color;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 100;
    letter-spacing: 0.325px;
   
  }
  
}
