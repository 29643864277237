@import "../../assets/scss/colors";
$LINEAR_SIZE: 0.6rem;
$RIPPLE_SIZE: 6rem;

.ripple {
  display: flex;
  width: inherit;
  height: inherit;
  align-items: center;
  justify-content: center;
  min-width: $RIPPLE_SIZE;
  div {
    position: absolute;
    border-width: ($RIPPLE_SIZE / 10);
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: zoom-in-ripple 1s cubic-bezier(0, 0.25, 0.5, 1) infinite;
  }
  div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes zoom-in-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      width: $RIPPLE_SIZE;
      height: $RIPPLE_SIZE;
      opacity: 0;
    }
  }
}

.linear {
  display: flex;
  width: inherit;
  height: inherit;
  align-items: center;
  justify-content: center;
  div {
    width: $LINEAR_SIZE;
    height: $LINEAR_SIZE;
    border-radius: 50% !important;
    -webkit-animation-fill-mode: both;
    margin-top: 1rem;
    margin-bottom: 1rem;
    animation-fill-mode: both;
    -webkit-animation: zoom-in 1.2s cubic-bezier(0, 0.25, 0.5, 1) infinite;
    animation: zoom-in 1.2s cubic-bezier(0, 0.25, 0.5, 1) infinite;
  }
  div:nth-child(1) {
    animation-delay: -0.5s;
  }
  div:nth-child(2) {
    animation-delay: -0.25s;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  div:nth-child(3) {
    animation-delay: 0s;
  }
  @keyframes zoom-in {
    0% {
      transform: scale(4);
    }
    50%, 100% {
      transform: scale(2);
    }
  }
}
