@import '../../assets/scss/colors.scss';
body.dark-theme{
    .progressBar{
    
        >.d-flex{
            align-content: space-between;
            justify-content: space-between;
            font-size: 0.875rem;
            line-height: 21px;
            .progress-title::before{
                content: url('../../assets/icons/progress/icon.png');
                position: relative;
                left: -10px;
                height: 12px;
                width: 10px;
                top: 3px;
                display: inline-block;
                filter: invert(100%) brightness(8.5);
            }
        }
        
        .progress {
            background: $slider-bg;
            height: 2px;
            width: 100% !important ;
            margin-bottom: 10px;
        }
        .primary-progress > span{
            position: relative;
            overflow: hidden;
            background: $slider-bg-active;
            width: attr(data-progress);
            height: 100%;
            border-radius: 9px;
        }
        .success-progress > span {
            display: block;
            height: 100%;
            background: $success-color-dark !important;;
            
            position: relative;
            overflow: hidden;
          }
        
        
    }
}
body.light-theme{
    .progressBar{
    
        >.d-flex{
            align-content: space-between;
            justify-content: space-between;
            font-size: 0.875rem;
            line-height: 21px;
            .progress-title::before{
                content: url('../../assets/icons/progress/icon.png');
                position: relative;
                left: -10px;
                height: 12px;
                width: 10px;
                top: 3px;
                display: inline-block;
                
            }
        }
        
        .progress {
            background: $slider-bg;
            height: 2px;
            width: 100% !important ;
            margin-bottom: 10px;
        }
        .primary-progress > span{
            position: relative;
            overflow: hidden;
            background: $slider-bg-active;
            width: attr(data-progress);
            height: 100%;
            border-radius: 9px;
        }
        .success-progress > span {
            display: block;
            height: 100%;
            background: $success-color-dark !important;;
            
            position: relative;
            overflow: hidden;
          }
        
        
    }
}

