@import "../../assets/scss/colors.scss";

body.light-theme {
    .discussionSidebar {
        border: none;
        .header {
            background: #fff;
            border: none;
            font-size: 0.9375rem;
            font-weight: 500;
            line-height: 24px;
            color: #0f1b41;
            /* identical to box height, or 185% */

            color: #0f1b41;
        }
        .discussionP {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 20px;
            margin-left: 31px;
            /* identical to box height, or 143% */

            color: #2567e7;
        }
        .discussionP::before {
            content: url("../../assets/icons/community-cards/discussion-icon.png");
            margin-right: 20px;
            left: 16px;
            height: 13px;
            position: absolute;
        }
    }
    .discussionModal {
        .classTitle {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.1px;

            color: #171725;
        }
        .postButton {
            font-family: "Inter" !important;
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 15px;
            text-align: center;
            color: #0e0e0e;
        }
        .discussionTextBox {
            outline: 0;
            border: none;
            resize: none;
            width: 100%;
            min-height: 100px;
        }
        .discussionTagBox {
            outline: 0;
            border: none;
            resize: none;
            width: 100%;
            min-height: 1.4rem;
        }
        .loader {
            border: 8px solid #f3f3f3;
            border-radius: 50%;
            border-top: 8px solid #3498db;
            width: 40px;
            height: 40px;
            -webkit-animation: spin 2s linear infinite; /* Safari */
            animation: spin 2s linear infinite;
            @-webkit-keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                }
            }
            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }
    .borderGrey {
        border: 1px solid #ccc;
        margin-top: 1rem;
        .discussionText {
            height: 40px;
            margin-right: 7px;
            width: 80%;
            outline: none;
            border: none;
            
        }
    }
}
body.dark-theme {
    .discussionSidebar {
        border: none;
        .header {
            background: #fff;
            border: none;
            font-size: 0.9375rem;
            font-weight: 500;
            line-height: 24px;
            color: #0f1b41;
            /* identical to box height, or 185% */

            color: #0f1b41;
        }
        .discussionP {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 20px;
            margin-left: 31px;
            /* identical to box height, or 143% */

            color: #2567e7;
        }
        .discussionP::before {
            content: url("../../assets/icons/community-cards/discussion-icon.png");
            margin-right: 20px;
            left: 16px;
            height: 13px;
            position: absolute;
        }
    }
    .discussionModal {
        .modal-content {
            background-color: $dark-mode-card;
        }
        .modal-header,
        .modal-footer {
            border: none;
        }
        .close {
            color: white;
        }
        .classTitle {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.1px;

            color: #fff;
        }
        .postButton {
            font-family: "Inter" !important;
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 15px;
            text-align: center;
            color: #0e0e0e;
        }
        .discussionTextBox {
            outline: 0;
            border: none;
            resize: none;
            width: 100%;
            min-height: 100px;
            background: $dark-mode-card;
            color: #fff !important;
            border-radius: 0px;
            padding: 4px 10px;
        }
        .discussionTagBox {
            outline: 0;
            border: none;
            resize: none;
            width: 100%;
            min-height: 1.4rem;
        }
        .loader {
            border: 8px solid #f3f3f3;
            border-radius: 50%;
            border-top: 8px solid #3498db;
            width: 40px;
            height: 40px;
            -webkit-animation: spin 2s linear infinite; /* Safari */
            animation: spin 2s linear infinite;
            @-webkit-keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                }
            }
            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }
    .borderGrey {
        border: 1px solid #ccc;
        margin-top: 1rem;
        .discussionText {
            height: 40px;
            margin-right: 7px;
            width: 80%;
            outline: none;
            border: none;
            background-color: $dark-mode-bg;
            color:#fff;
        }
    }
}
@media (max-width: 800px) {
    body.light-theme {
        .mobileStrip {
            background: white;
        }
        .startDiscussionCardFooter {
            width: 100% !important;
            display: grid !important;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            grid-gap: 10px;
        }
        .discussionTextBox {
            outline: 0;
            border: none;
            resize: none;
            width: 100%;
            min-height: 100px;
        }
        .select-holder {
            justify-content: flex-end !important;
            #c-discussionCategory {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
    body.dark-theme {
        .mobileStrip {
            background: $dark-mode-bg;
        }
        .startDiscussionCardFooter {
            width: 100% !important;
            display: grid !important;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            grid-gap: 10px;
        }
        .postButton {
            font-family: "Inter" !important;
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 15px;
            text-align: center;
            color: #0e0e0e;
        }
        .discussionTextBox {
            outline: 0;
            border: none;
            resize: none;
            width: 100%;
            min-height: 100px;
            background: $dark-mode-bg;
            color: #fff;
            padding: 10px;
        }
        .select-holder {
            justify-content: flex-end !important;
            #c-discussionCategory {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
}
