@import "../../assets/scss/colors.scss";

/* SHIMMER EFFECT */
body.dark-theme {
  .shine {
    background: $dark-mode-bg;
    background-image: linear-gradient(
      to right,
      $dark-mode-bg 0%,
      $dark-mode-card 20%,
      $dark-mode-bg 40%,
      $dark-mode-card 100%
    );
    background-repeat: no-repeat;
    background-size: 200px 200px;
    display: inline-block;
    position: relative;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
    &.box {
      border: 1px solid $dark-mode-card;
      height: 200px;
      width: 200px;
    }
  }
  .bg-white {
    background: $dark-mode-card !important;
  }
}
body.light-theme {
  .shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 200px 200px;
    display: inline-block;
    position: relative;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
    &.box {
      border: 1px solid #e4e4e4;
      height: 200px;
      width: 200px;
    }
  }
}

.shimmer > div.longlines {
  display: inline-flex;
  flex-direction: column;

  vertical-align: top;
}

.lines {
  height: 10px;
  margin-top: 10px;
  width: 80%;
}

.photo {
  display: block !important;
  width: 100px;
  height: 50px;
  margin-top: 15px;
  border-radius: 10px;
}
@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
