@import "../../assets/scss/colors.scss";

.todayButton {
    background: #ffffff;
    border: 1px solid rgba(23, 62, 103, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 2px 3px rgba(20, 50, 80, 0.05);
    border-radius: 4px;
    color: $primary-text-color;
    &:focus {
        outline: none !important;
    }
}
.nextButton,
.prevButton {
    &:hover {
        border: 1px solid #ccc;
    }
}

.month-popup {
    position: absolute;
    background: #fff;
    padding: 20px;
    box-shadow: 0px 0px 1px black;
    z-index: 2;
    div {
        a {
            font-size: 0.8rem;
            color: $primary-text-color;
            text-align: center;
        }
        .convertButtonToLink{
            background: none!important;
          padding: 0!important;
          font-size: 0.8rem;
            color: $primary-text-color;
            text-align: center;
          cursor: pointer;
            &:hover{
                text-decoration: underline;
                outline: none;
            }
        }
    }
}
.editor-year {
    box-shadow: 0px 0px 1px black;
    color: $primary-text-color;
    font-family: Inter;
    font-style: normal;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding-left: 10px;
    width: 90px;
}
.table {
    thead {
        .calendar-header {
            height: 35px;
            td {
                height: 54px;
                display: inline-flex;
                align-items: center;
            }
        }
    }
}

body.light-theme {
    .label-month,
    .label-year {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: calc(1rem);
        line-height: 33px;
        color: $primary-text-color;
        cursor: pointer;
    }
    .emptySlot {
        background: #f5f8fa;
        border: 1px solid #eff3f6;
    }
    div.eventDataGroup {
        height: 100px;
        overflow: hidden;
        overflow-y: scroll;
        padding: 0.3rem;
        .no-event{
            color: $primary-text-color;
            font-size: 1.2rem;
        }
        .eventData {
            font-size: 1rem;
            line-height: 12px;
            border-left: 3px solid $yellow-dark;
            min-height: 100px;
            letter-spacing: -0.1px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.invalidDate {
                display: none !important;
            }
            a {
                text-decoration: none;
                color: #000;
                font-style: normal;
                font-weight: 600;
                line-height: initial;
            }
            
        }
    }
    .day {
        height: 104px;
        width: 200px;

        border: 1px solid #eff3f6;
       
        div.dates {
            cursor: pointer;
            display: grid;
            justify-content: center;
            padding: 10px 30px;
            align-items: center;
            height: inherit;
            p {
                font-family: "Inter";
                font-style: normal;
                font-weight: normal;
                font-size: 1rem;
                line-height: 0.75rem;
                /* identical to box height, or 120% */
                letter-spacing: -0.1px;

                color: #000;

                mix-blend-mode: normal;
                opacity: 0.4;
                &.current-day {
                    border-radius: 50%;
                    padding: 6px;
                    color: #0070ff;
                    width: 20px;
                    height: 20px;
                    align-items: center;
                    display: inline-flex;
                }
            }
        }
    }
}
body.dark-theme {
    .label-month,
    .label-year {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: calc(1rem);
        line-height: 33px;
        color: #fff;
        cursor: pointer;
    }
    .emptySlot {
        background: $dark-mode-bg;
        border: 1px solid $dark-mode-card;
    }
    div.eventDataGroup {
        height: 100px;
        overflow: hidden;
        overflow-y: scroll;
        padding: 0.3rem;
        .no-event{
            color: white;
            font-size: 1.2rem;
        }
        div.eventData {
            background-color: $dark-mode-bg;
            font-size: 1rem;
            line-height: 12px;
            border-left: 3px solid $yellow-dark;
            min-height: 100px;
            letter-spacing: -0.1px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.invalidDate {
                display: none !important;
            }
            
            div{
                a {
                    text-decoration: none;
                    font-style: normal;
                    font-weight: 600;
                    color: white;
                    line-height: initial;
                }
            }
           
        }
    }
    .day {
        height: 104px;
        width: 200px;

        border: 1px solid $dark-mode-bg;
       
        div.dates {
            cursor: pointer;
            display: grid;
            justify-content: center;
            padding: 10px 30px;
            align-items: center;
            height: inherit;
             p {
                font-family: "Inter";
                font-style: normal;
                font-weight: normal;
                font-size: 1rem;
                line-height: 0.75rem;
                /* identical to box height, or 120% */
                letter-spacing: -0.1px;

                color: #fff;

                mix-blend-mode: normal;
                opacity: 0.4;
                &.current-day {
                    border-radius: 50%;
                    padding: 6px;
                    color: $yellow-dark;
                    width: 20px;
                    height: 20px;
                    align-items: center;
                    display: inline-flex;
                }
            }
        }
    }
}
