@import '../../../assets/scss/colors.scss';

// body.dark-theme{
    circle.svg-circle[stroke="#14A277"] {
        stroke: #14A277 !important;
    }
    circle.svg-circle[stroke="#0062FF"] {
        stroke: #0062FF !important;
    }
    #courseStatsPending{
        fill: #0062FF !important;
    }
    #courseStatsPassed{
        fill: #14A277 !important;
    }
    .svg {
        display: block;
        margin: 20px auto;
        max-width: 100%;
    }
    
    .svg-circle-bg {
        fill: none;
    }
    
    .svg-circle {
        fill: none;
    }
    .svg-circle-text {
    //    font-size: 2rem;
        text-anchor: middle;
        fill: #fff;
        // font-weight: bold;
    }
// }

// body.light-theme{
//     circle.svg-circle[stroke="#FFE001"]{
//         stroke: $primary-text-color !important;
//     }
//     .svg {
//         display: block;
//         margin: 20px auto;
//         max-width: 100%;
//     }
//     #courseStatsPending{
//         fill: #0062FF !important;
//     }
//     #courseStatsPassed{
//         fill: #14A277 !important;
//     }
//     .svg-circle-bg {
//         fill: none;
//     }
    
//     .svg-circle {
//         fill: none;
//     }
//     .svg-circle-text {
//     //    font-size: 2rem;
//         text-anchor: middle;
//         fill: #fff;
//         // font-weight: bold;
//     }
// }