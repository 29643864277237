@import "../assets/scss/mixins";
@import "../assets/scss/custom-buttons";
@import "../assets/scss/colors";


.App {
  @include fill-parent;
}
.App-body{
  position: relative;
  transition: all .2s ease-out;
  // margin-bottom:5rem;
}
.twoRem{
  font-size: 1.8rem;
  
}
.yellowBackground{
  background-color: $yellow-dark;
}
.yellowLightBackground{
  background-color: $yellow-light;
}
body.light-theme .card
body.light-theme  .card-body,
body.light-theme  .card-header,
body.light-theme   .card-footer{
  background: #fff;
}
.no-border{
  border: none !important
}
.btn:not(.no-lift), .lift {
  transition: box-shadow .2s cubic-bezier(.4,0,.2,1);
}
body.light-theme{
 
  .btn:not(.no-lift):hover, .lift:hover {
    box-shadow: 0 .625rem .875rem -.3125rem rgba(0,0,0,.3);
  }
  .noDiscussionH1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 30px;
    color:$primary-text-color;
    /* identical to box height */
    text-align: center;
  }
  .noDiscussionP{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 25px;
    width:70%;
    /* or 143% */
    text-align: center;
    
    color: #707A89;
  }
}
body.dark-theme{
  .noDiscussionH1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 30px;
    color:white;
    /* identical to box height */
    text-align: center;
  }
  .noDiscussionP{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 25px;
    width:70%;
    /* or 143% */
    text-align: center;
    
    color: #fff;
  }
  .btn:not(.no-lift):hover, .lift:hover {
    box-shadow: 0 .625rem .875rem -.3125rem rgba(0, 0, 0, 0.3);
  }
}

