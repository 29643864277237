@import "../../assets/scss/colors.scss";
body.light-theme {
    .profile-card-header {
        background: url("../../assets/img/profile/profile-top.png") !important;
        background-repeat: no-repeat;
        border: none;
        min-height: 116px;
        background-size: 100% 100% !important;
        p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 33px;

            /* identical to box height, or 184% */
            text-align: center;

            color: #0e0e0e;
        }
    }
    .profile-card-header-2 {
        background: #fff;
        border: none;
    }

    .profile-card-2 .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 24px;
        color: #0f1b41;
    }
    .profile-card-2 .card-body p.education {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 0.8125rem;
        line-height: 24px;

        /* identical to box height, or 185% */

        color: #0f1b41;
    }

    .profile-card-2 .card-body p.school {
        font-family: "Inter";
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 24px;
        color: #5a7180;
    }
    .avatar-c {
        border: 5px solid #d8d8d8;
    }
    
    .avatar-c-holder{
        cursor: pointer;
        &:hover{
            i.fa{
                opacity: 1 !important;
            }
        } 
    }
    .profileCard .key {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 23px;

        /* identical to box height, or 167% */

        color: #0e0e0e;
    }
    .profileCard .value {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 23px;

        /* identical to box height, or 195% */
        text-align: right;

        color: #4b4c4f;
    }
    .profileCard .username {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 23px;

        color: #0f1b41;
    }
    .profileCard .userlocation {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 11.6963px;
        line-height: 23px;

        /* identical to box height, or 200% */

        color: #5a7180;
    }
}
body.dark-theme {
    .discussion-title-bar {
        span {
            color: #fff;
        }
    }
    .profile-card-header {
        background: url("../../assets/img/profile/profile-top.png") !important;
        background-repeat: no-repeat;
        border: none;
        min-height: 116px;
        background-size: 100% 100% !important;
        p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 33px;

            /* identical to box height, or 184% */
            text-align: center;

            color: #0e0e0e;
        }
    }
    .profile-card-header-2 {
        background: $dark-mode-card;
        border: none;
    }

    .profile-card-2 .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 24px;
        color: #fff;
    }
    .profile-card-2 .card-body p.education {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 0.8125rem;
        line-height: 24px;

        /* identical to box height, or 185% */

        color: #fff;
    }

    .profile-card-2 .card-body p.school {
        font-family: "Inter";
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 24px;
        color: #fff;
    }
    .avatar-c {
        border: 5px solid #fff;
    }
    .avatar-c-holder{
        cursor: pointer;
        &:hover{
            i.fa{
                opacity: 1 !important;
            }
        } 
    }
    .profileCard .key {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 23px;

        /* identical to box height, or 167% */

        color: #fff;
    }
    .profileCard .value {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 23px;

        /* identical to box height, or 195% */
        text-align: right;

        color: #fff;
    }
    .profileCard .username {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 23px;

        color: #fff;
    }
    .profileCard .userlocation {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 11.6963px;
        line-height: 23px;

        /* identical to box height, or 200% */

        color: #fff;
    }
}
