@import "colors";
@import "mixins";
@import "grid";
@import "animations";

@import "~bootstrap/scss/bootstrap";



.small-text {
  font-size: .9rem;
}

.form-control:focus, .btn:focus {
  box-shadow: none;
  border-color: inherit;
}

.cursor-pointer{
  cursor: pointer;
}

.form-group:hover .formMessage {
  display: block;
  &.error {
    background-color: $danger!important;
    &:before {
      border: 0.5rem solid $danger!important;
    }
  }
}

.social-icon {
  display: inline-flex;
  margin: 0.5rem;
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  background-color: $light;
  color: $primary;
  img {
    width: 100%;
  }
  * {
    font-size: 1rem !important;
  }
  &:hover {
    cursor: pointer;
  }
}

.rounded-btn {
  border: 0.1rem solid #cccccc;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  text-align: center;
  min-width: 4rem;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.text-faded {
  color: #ABABB6;
}
/* Custom grid ends here */

ul.ul-dash, ul.ul-star {
  list-style: none;
  margin-left: 0;
  padding-left: 1rem;
}

ul.ul-dash > li:before, ul.ul-star > li:before {
  display: inline-block;
  width: 1rem;
  margin-left: -1rem;
}

ul.ul-dash > li:before {
  content: "-";
}

ul.ul-star > li:before {
  content: "*";
}

i.next-prev {
  border: solid $primary;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
}

.next {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.prev {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
//*::-webkit-scrollbar{
//  width: 0.5rem;
//}
//*::-webkit-scrollbar-track{
//  background-color: transparent;
//}
//*::-webkit-scrollbar-thumb{
//  background-color: transparent;
//}
//*:hover  {
//  &::-webkit-scrollbar-track{
//    background-color: transparent;
//  }
//  &::-webkit-scrollbar-thumb{
//    background-color: $primary;
//    border-radius: 2rem;
//    cursor: pointer;
//  }
//}
@include media-breakpoint-up(md) {
  .PaginationItemGroup {
    position: absolute;
    right: 0;
  }
}
