.hover-animate-child {
  .dissolve {
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: all .5s linear;
  }
  &:active, &:focus, &:hover, *:active, *:focus, *:hover {
    .dissolve{
      visibility: visible;
      opacity: 1;
    }
  }
}
