@font-face {
  font-family: "SF Pro Display";
  src: url("./sf/FontsFree-Net-SFProText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("./sf/FontsFree-Net-SFProText-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Mosk";
  src: url("./mosk/Mosk-Thin-100.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "Mosk";
  src: url("./mosk/Mosk-Extra-Light-200.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Mosk";
  src: url("./mosk/Mosk-Light-300.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Mosk";
  src: url("./mosk/Mosk-Normal-400.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mosk";
  src: url("./mosk/Mosk-Medium-500.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Mosk";
  src: url("./mosk/Mosk-Semi-Bold-600.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Mosk";
  src: url("./mosk/Mosk-Bold-700.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Mosk";
  src: url("./mosk/Mosk-Extra-Bold-800.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Mosk";
  src: url("./mosk/Mosk-Ultra-Bold-900.ttf") format("truetype");
  font-weight: 900;
}

$font-weight: (100, 200, 300, 500, 600, 800, 900);

.catchy {
  font-size: 3.6rem;
  font-weight: bold;
}

.catchy2 {
  font-size: 3.2rem;
  font-weight: bold;
}

@each $val in $font-weight{
  .font-weight-#{$val} {
    font-weight: $val !important;
  }
}
