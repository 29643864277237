@import "./colors";
.addBtnArrow:after{
  content: url('../icons/next.png');
  margin-left: 0.7rem;
  vertical-align:middle;
}
.paymentBtn{
  padding: .375rem .75rem;
}
.btn{
  font-size: clamp(1rem, 1vw, 1.25rem) !important;
  font-weight: 500 !important;
}

.sme-btn-primary>span{
 height:20px;
}

.btn.sme-btn-secondary, .btn.sme-btn-secondary:disabled{
  background-color: $primary-text-color!important;
  color: $yellow-dark!important;
  border-color: $primary-text-color!important;
  font-weight: 600;
  transition: color 1s ease-in-out;
}

body.dark-theme{
  .sme-btn-dark,.sme-btn-dark:disabled{
    background-color: $yellow-dark ;
    color: $primary-text-color;
    border-color: $yellow-dark;
    font-weight: 600;
    transition: color 1s ease-in-out;
  }
  .sme-btn-primary, .sme-btn-primary:disabled{
    background-color: $yellow-dark ;
    color: $primary-text-color;
    border-color: $yellow-dark;
    font-weight: 600;
    transition: color 1s ease-in-out;
  }

  .walkthroughButton:after{
      content: url('../icons/next.png');
      margin-left: 1.25rem;
  }

  .sme-btn-primary.focus, .sme-btn-primary:focus, .sme-btn-primary:hover,
  .sme-btn-primary.btn.focus, .sme-btn-primary.btn:focus{
    color: $primary-text-color;
    background:  #dabf00 ;

    border: none;
    box-shadow: 0 0 0 0.1rem rgba(0,0,0,0);
  }
  .sme-btn-primary:not(:disabled):not(.disabled).active,
   .sme-btn-primary:not(:disabled):not(.disabled):active,
   .show>.sme-btn-primary.dropdown-toggle {
    color: $primary-text-color;
    background-color: #dabf00;
    border: none;
    box-shadow: 0 0 0 0.1rem rgba(0,0,0,0);
  }

  .sme-btn-primary:not(:disabled):not(.disabled).active:focus,
  .sme-btn-primary:not(:disabled):not(.disabled):active:focus,
  .show>.sme-btn-primary.dropdown-toggle:focus{
    box-shadow: 0 0 0 0.1rem rgba(0,0,0,0);
  }
  .sme-btn-link-white{
    background-color: #fff ;
    color: $primary-text-color;
    border-color: #fff;
  }
  .sme-btn-white{
    background-color: $dark-mode-card ;
    color: white;
    border-color: $dark-mode-bg ;
  }
  .sme-btn-white.focus, .sme-btn-white:focus, .sme-btn-white:hover,
  .sme-btn-white.btn.focus, .sme-btn-white.btn:focus {
    color: white;
    background-color: $dark-mode-bg !important;
    border-color: $dark-mode-bg ;
    box-shadow: none !important;
  }
  .sme-btn-white:not(:disabled):not(.disabled).active,
  .sme-btn-white:not(:disabled):not(.disabled):active,
  .show>.sme-btn-white.dropdown-toggle {
    color: white;
    background-color: $dark-mode-card !important;
    border-color: $dark-mode-bg ;
    // box-shadow: 0 0 0 0.2rem $primary-text-color;
 }
  .sme-btn-white:not(:disabled):not(.disabled).active:focus,
  .sme-btn-white:not(:disabled):not(.disabled):active:focus,
  .show>.sme-btn-white.dropdown-toggle:focus{
     color: white;
    //  box-shadow: 0 0 0 0.2rem $primary-text-color;
  }

  .sme-btn-success{
    background-color: $success-color ;
    color: $primary-text-color;
    font-weight: 600;
    border:none;
    font-size:1rem;
  }
  .sme-btn-success.focus, .sme-btn-success:focus, .sme-btn-success:hover,
  .sme-btn-success.btn.focus, .sme-btn-success.btn:focus {
    color: $primary-text-color;
    background-color: $success-color !important;
    border-color: $success-color-dark;
    box-shadow: 0 0 0 0.2rem $success-color-dark;
  }
  .sme-btn-success:not(:disabled):not(.disabled).active,
   .sme-btn-success:not(:disabled):not(.disabled):active,
   .show>.sme-btn-success.dropdown-toggle {
    color: $primary-text-color;
    background-color: $success-color !important;
    border-color: $success-color-dark;
  }



  .sme-btn-success:not(:disabled):not(.disabled).active:focus,
  .sme-btn-success:not(:disabled):not(.disabled):active:focus,
  .show>.sme-btn-success.dropdown-toggle:focus{
    box-shadow: 0 0 0 0.2rem $success-color-dark;
  }
  .sme-btn-grey{
    background-color: #F1F1F5 ;
    color: $primary-text-color;
    font-weight: 600;
    border:none;
    font-size:1rem;
  }
  .sme-btn-grey.focus, .sme-btn-grey:focus, .sme-btn-grey:hover,
  .sme-btn-grey.btn.focus, .sme-btn-grey.btn:focus {
    color: $primary-text-color;
    background-color: #F1F1F5 !important;
    border-color: #F1F1F5;
    box-shadow: none;
  }
  .sme-btn-grey:not(:disabled):not(.disabled).active,
   .sme-btn-grey:not(:disabled):not(.disabled):active,
   .show>.sme-btn-success.dropdown-toggle {
    color: $primary-text-color;
    background-color: #F1F1F5 !important;
    border-color:   #F1F1F5;
  }



  .sme-btn-success:not(:disabled):not(.disabled).active:focus,
  .sme-btn-success:not(:disabled):not(.disabled):active:focus,
  .show>.sme-btn-success.dropdown-toggle:focus{
    box-shadow: 0 0 0 0.2rem #F1F1F5;
  }
}
body.light-theme{
  .sme-btn-dark,.sme-btn-dark:disabled{
    background-color: #0e0e0e;
    border-color: #0e0e0e;
    color: #ffe001!important;
    transition: color 1s ease-in-out;
  }
  .sme-btn-primary, .sme-btn-primary:disabled{
    background-color: $yellow-dark ;
    color: $primary-text-color;
    border-color: $yellow-dark;
    transition: color 1s ease-in-out;
  }
  .walkthroughButton:after{
      content: url('../icons/next.png');
      margin-left: 1.25rem;
  }
  .sme-btn-primary.focus, .sme-btn-primary:focus, .sme-btn-primary:hover,
  .sme-btn-primary.btn.focus, .sme-btn-primary.btn:focus{
    color: $primary-text-color;
    background:  #dabf00 ;

    border: none;
    box-shadow: 0 0 0 0.1rem rgba(0,0,0,0);
  }
  .sme-btn-primary:not(:disabled):not(.disabled).active,
   .sme-btn-primary:not(:disabled):not(.disabled):active,
   .show>.sme-btn-primary.dropdown-toggle {
    color: $primary-text-color;
    background-color: #dabf00;
    border: none;
    box-shadow: 0 0 0 0.1rem rgba(0,0,0,0);
  }






  .sme-btn-primary:not(:disabled):not(.disabled).active:focus,
  .sme-btn-primary:not(:disabled):not(.disabled):active:focus,
  .show>.sme-btn-primary.dropdown-toggle:focus{
    box-shadow: 0 0 0 0.1rem rgba(0,0,0,0);
  }
  .sme-btn-link-white{
    background-color: #fff ;
    color: $primary-text-color;
    border-color: #fff;

  }
  .sme-btn-white{
    background-color: #fff ;
    color: $primary-text-color;
    border-color: #fff;

  }
  .sme-btn-white.focus, .sme-btn-white:focus, .sme-btn-white:hover,
  .sme-btn-white.btn.focus, .sme-btn-white.btn:focus {
    color: $primary-text-color;
    background-color: #fff!important;
    border-color: $primary-text-color;
    box-shadow: none !important;
  }
  .sme-btn-white:not(:disabled):not(.disabled).active,
  .sme-btn-white:not(:disabled):not(.disabled):active,
  .show>.sme-btn-white.dropdown-toggle {
    color: $primary-text-color;
    background-color: #fff !important;
    border-color: $primary-text-color;
    // box-shadow: 0 0 0 0.2rem $primary-text-color;
 }
  .sme-btn-white:not(:disabled):not(.disabled).active:focus,
  .sme-btn-white:not(:disabled):not(.disabled):active:focus,
  .show>.sme-btn-white.dropdown-toggle:focus{
     color: $primary-text-color;
    //  box-shadow: 0 0 0 0.2rem $primary-text-color;
  }

  .sme-btn-success{
    background-color: $success-color ;
    color: $primary-text-color;
    font-weight: 600;
    border:none;
    font-size:1rem;
  }
  .sme-btn-success.focus, .sme-btn-success:focus, .sme-btn-success:hover,
  .sme-btn-success.btn.focus, .sme-btn-success.btn:focus {
    color: $primary-text-color;
    background-color: $success-color !important;
    border-color: $success-color-dark;
    box-shadow: 0 0 0 0.2rem $success-color-dark;
  }
  .sme-btn-success:not(:disabled):not(.disabled).active,
   .sme-btn-success:not(:disabled):not(.disabled):active,
   .show>.sme-btn-success.dropdown-toggle {
    color: $primary-text-color;
    background-color: $success-color !important;
    border-color: $success-color-dark;
  }



  .sme-btn-success:not(:disabled):not(.disabled).active:focus,
  .sme-btn-success:not(:disabled):not(.disabled):active:focus,
  .show>.sme-btn-success.dropdown-toggle:focus{
    box-shadow: 0 0 0 0.2rem $success-color-dark;
  }
  .sme-btn-grey{
    background-color: #F1F1F5 ;
    color: $primary-text-color;
    font-weight: 600;
    border:none;
    font-size:1rem;
  }
  .sme-btn-grey.focus, .sme-btn-grey:focus, .sme-btn-grey:hover,
  .sme-btn-grey.btn.focus, .sme-btn-grey.btn:focus {
    color: $primary-text-color;
    background-color: #F1F1F5 !important;
    border-color: #F1F1F5;
    box-shadow: none;
  }
  .sme-btn-grey:not(:disabled):not(.disabled).active,
   .sme-btn-grey:not(:disabled):not(.disabled):active,
   .show>.sme-btn-success.dropdown-toggle {
    color: $primary-text-color;
    background-color: #F1F1F5 !important;
    border-color:   #F1F1F5;
  }



  .sme-btn-success:not(:disabled):not(.disabled).active:focus,
  .sme-btn-success:not(:disabled):not(.disabled):active:focus,
  .show>.sme-btn-success.dropdown-toggle:focus{
    box-shadow: 0 0 0 0.2rem #F1F1F5;
  }



}

