.Header {

  // height: 53vh;
  .HeaderContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    width: inherit;
    background-size: 100% 100%;
    color: white;

    .filterComponent {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .btns {
        font-size: 0.85rem !important;
      }

      .btns:hover {
        font-size: 0.85rem !important;
        text-decoration: none !important;
      }

      .filterBg {
        width: 42px !important;
        height: 42px !important;
        background: #fff;
        border: 0.68px solid #f2f2f2;
        display: grid;
        justify-content: center;
        align-content: center;
      }
    }
  }
}

@media(max-width:800px) {
  .filterComponent {
    width: 100% !important;
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 10px;
  }

  .select-holder {
    justify-content: flex-end !important;
  }
}

.noCourseImg {
  width: 13.5rem;
  margin-bottom: 3rem;
}

.welcome {
  min-height: 3rem;
}

.min-height {
  min-height: 1rem;
}

.horizontalSlider {
  display: block;
  overflow-x: scroll;
  width: 100%;
}

.horizontalSlider::-webkit-scrollbar {
  display: none;
}

.sliderContainer {
  white-space: nowrap;
  display: block;
}

.sliderContainer>div {
  display: inline-block;
  width: 70%;
}

@media (min-width: 310px) and (max-width: 736px) {
  .divSwitch {
    display: flex;

    .order1 {
      order: 1;
    }

    .order2 {
      order: 2;
    }
  }
}