@import "../../assets/scss/colors.scss";

body.light-theme {
    .search-content {
        position: absolute;
        background-color: #f9f9f9;
        min-width: 200px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        top: 78px;
        width: max-content;
        width: max-content;
        ol {
            list-style: none;
            li {
                a{
                    color:black;
                    text-decoration: none;
                    cursor: pointer;
                }
                padding: 10px;
                background: white;
                cursor: pointer;
                font-size: 0.9rem;
            }
        }
    }
    .searchForm {
        .holder {
            display: flex;
        }

        .search {
            transition: width 1s, opacity 1s;
            opacity: 0;
            outline: none;
            border: none;
            padding: 2px 9px;
            width: 0px;
            background-color: $body-light-background;
            box-shadow: none;
        }
        .searchButton {
            position: relative;
            cursor: pointer;
            font-size: 1.2rem;
            margin-top: 0.3rem;
        }
    }
}
body.dark-theme {
  
    .search-content {
        position: absolute;
        background-color: #f9f9f9;
        min-width: 200px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        top: 78px;
        width: max-content;
        ol {
            list-style: none;
            li {
                a{
                    color:white;
                    text-decoration: none;
                    cursor: pointer;
                }
                padding: 10px;
                background: $dark-mode-card;
                cursor: pointer;
                font-size: 0.9rem;
            }
        }
    }
    .searchForm {
        .holder {
            display: flex;
        }
       
        .search {
            transition: width 1s, opacity 1s;
            outline: none;
            border: none;
            background: $dark-mode-bg;
            color: white;
            padding: 2px 9px;
            width: 0px;
            box-shadow: none;
        }
        .searchButton {
            position: relative;
            cursor: pointer;
            font-size: 1.2rem;
            margin-top: 0.3rem;
            color: white !important;
        }
    }
}
@media (max-width: 500px){
   .search-content ol li{
        font-size: 0.66rem !important;
        padding: 10px 5px !important;
    }
}
.searchActive {
    background: linear-gradient($yellow-dark, $yellow-dark), linear-gradient($yellow-dark, $yellow-dark),
        linear-gradient($yellow-dark, $yellow-dark) !important;
    background-size: 1px 20%, 100% 1px, 1px 20% !important;
    background-position: bottom left, bottom center !important;
    background-repeat: no-repeat !important;
    border: none !important;
    opacity: 1 !important;
    width: 200px !important;
}
