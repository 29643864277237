body.dark-theme{
    .courseProgress .coursecount{
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 1.6875rem;
        line-height: 41px;
        text-align: center;
        letter-spacing: 0.113531px;
        padding: 3px;
        color: #fff;
        fill: #fff;
    }
    .courseProgress img{
        height: 102px;
    }
    .courseProgress .courseCountTitle{
        font-family: 'Inter';
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.113531px;
        fill: #fff;
        color: #fff;
    }
    
    .progressCard{
        border:none
    }
}
body.light-theme{
    .courseProgress .coursecount{
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 1.6875rem;
        line-height: 41px;
        text-align: center;
        letter-spacing: 0.113531px;
        padding: 3px;
        color: #171725;
        fill: #171725;
    }
    .courseProgress img{
        height: 102px;
    }
    .courseProgress .courseCountTitle{
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.113531px;
        fill: #4B4C4F;
        color: #4B4C4F;
    }
    
    .progressCard{
        border:none
    } 
}
