@import '../../assets/scss/colors.scss';
body.light-theme{
   .profileLabel{
    height: 40px;
    background: #e9ecef;
    display: flex;
    align-items: center;
   }
    .form-control:focus {
        color: #495057;
        background-color: #fff;
        border-color: #4e4e4e;
        border-left-color: rgb(78, 78, 78);
        outline: 0;
        box-shadow: 0 0 0 .2rem rgba(14,14,14,.25);
    }
    .form-control:hover {
        border-left: .8rem solid rgba(14,14,14,.6);
    }
    .form-control:active, .form-control:focus {
        box-shadow: none;
        border-left: .8rem solid #0e0e0e;
    }
    .form .formMessage {
        display: none;
        position: absolute;
        min-width: 10rem;
        max-width: 25rem;
        border-radius: .5rem;
        top: -2.3rem;
        right: 0;
        background-color: #0e0e0e;
        color: #fff;
        box-shadow: -3px -3px 6px rgba(0,0,0,.3);
        padding: .3rem .8rem;
        font-size: .9rem;
        height: auto;
        transition: all 2s ease-out;
        z-index: 5;
        span {
            white-space: nowrap;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:before {
            content: "";
            position: absolute;
            border: .5rem solid #0e0e0e;
            z-index: 2;
            bottom: -.5rem;
            right: 10%;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            height: 0;
            width: 0;
        }
        &.error {
            background-color: #dc3545!important;
            &:before {
                border: .5rem solid #dc3545!important;
            }
        }
        &.success {
            background-color: $success-color-dark!important;
        }
    }
    
    
    .form .form-group div.position-relative:active 
    .formMessage.show, 
    .form .form-group div.position-relative:hover
     .formMessage.show {
        display: block;
    }
    textarea.form-control{
        height: 70px;
    }
    .form-control {
        display: block;
        width: 100%;
        height: 40px;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-clip: padding-box;
        border: 1px solid #E0E7FF;
        box-sizing: border-box;
        border-radius: 5px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        &:disabled{
            border: none !important;
        }
    }
    textarea.form-control {
        outline:0;
        resize:none;
    }
    .form-control::placeholder {
    
        color: #6c757d;
        opacity: 1;
    
    }
    .form-control.error {
        border: 1px solid #dc3545;
            border-left-color: rgb(220, 53, 69);
            border-left-style: solid;
            border-left-width: 1px;
    }
    .form-control.error:active, .form-control.error:focus {
        box-shadow: none;
        border-left: .8rem solid #dc3545;
    }
    
    label{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 17px;
        color: $menu-header;
    }
    .card-title{
        font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 34px;
    
    /* identical to box height, or 189% */
    
    color: $menu-header;
    }
    
    .small-menu-li{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    
    line-height: 20px;
    }
}
body.dark-theme{
    .profileLabel{
        height: 40px;
        background: #080e29;
        display: flex;
        align-items: center;
       }
    .form-control:focus {
        color: #495057;
        background-color: #fff;
        border-color:#ffffff69;
        border-left-color: rgba(225, 225, 225, 0.23);;
        outline: 0;
        box-shadow: 0 0 0 .2rem rgba(14,14,14,.25);
    }
    .form-control:hover {
        
        border-left:.8rem solid rgba(225, 225, 225, 0.23);
        //  0.8rem solid rgb(225 225 225 / 23%); 
        // .8rem solid rgba(225,225,225,.9);
    }
    .form-control:active, .form-control:focus {
        box-shadow: none;
        color:#fff;
        
        border-left: .8rem solid rgba(225,225,225,0.23);
        background-color: $dark-mode-bg;
    }
    .form .formMessage {
        display: none;
        position: absolute;
        min-width: 10rem;
        max-width: 25rem;
        border-radius: .5rem;
        top: -2.3rem;
        right: 0;
        background-color: #0e0e0e;
        color: #fff;
        box-shadow: -3px -3px 6px rgba(0,0,0,.3);
        padding: .3rem .8rem;
        font-size: .9rem;
        height: auto;
        transition: all 2s ease-out;
        z-index: 5;
        span {
            white-space: nowrap;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:before {
            content: "";
            position: absolute;
            border: .5rem solid #0e0e0e;
            z-index: 2;
            bottom: -.5rem;
            right: 10%;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            height: 0;
            width: 0;
        }
        &.error {
            background-color: #dc3545!important;
            &:before {
                border: .5rem solid #dc3545!important;
            }
        }
        &.success {
            background-color: $success-color-dark!important;
        }
    }
    
    
    .form .form-group div.position-relative:active 
    .formMessage.show, 
    .form .form-group div.position-relative:hover
     .formMessage.show {
        display: block;
    }
    textarea.form-control{
        height: 70px;
    }
    .form-control {
        display: block;
        width: 100%;
        height: 40px;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: #fff;
        background-color: $dark-mode-bg;
        background-clip: padding-box;
        border: 1px solid #cccccc1c;
        box-sizing: border-box;
        border-radius: .5rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        &:disabled{
            border: none !important;
        }
    }

    textarea.form-control {
        outline:0;
        resize:none;
    }
    .form-control::placeholder {
        color: #495057;
        opacity: 1;
    }
    .form-control.error {
        border: 1px solid #dc3545;
            border-left-color: rgb(220, 53, 69);
            border-left-style: solid;
            border-left-width: 1px;
    }
    .form-control.error:active, .form-control.error:focus {
        box-shadow: none;
        border-left: .8rem solid #dc3545;
    }
    
    label{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 17px;
        color: #fff;
    }
    .card-title{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 34px;
    
        /* identical to box height, or 189% */
    
        color: #fff;
    }
    
    .small-menu-li{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    
    line-height: 20px;
    }
    .form-select::after {
        content: "\25bc"; /* HTML symbol */
        /* Reposition */
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 10px;
        line-height: 40px; /* Vertical center text */
      }
}
