@import "../../assets/scss/colors.scss";

.todayButton {
    background: #ffffff;
    border: 1px solid rgba(23, 62, 103, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 2px 3px rgba(20, 50, 80, 0.05);
    border-radius: 4px;
    color: $primary-text-color;
    &:focus {
        outline: none !important;
    }
}
.nextButton,
.prevButton {
    &:hover {
        border: 1px solid #ccc;
    }
}

.month-popup {
    position: absolute;
    background: #fff;
    padding: 20px;
    box-shadow: 0px 0px 1px black;
    z-index: 2;
    div {
        a {
            font-size: 0.8rem;
            color: $primary-text-color;
            text-align: center;
        }
        .convertButtonToLink{
            background: none!important;
          padding: 0!important;
          cursor: pointer;
            &:hover{
                text-decoration: underline;
                outline: none;
            }
        }
    }
}
.editor-year {
    box-shadow: 0px 0px 1px black;
    color: $primary-text-color;
    font-family: Inter;
    font-style: normal;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding-left: 10px;
    width: 90px;
}
.table {
    thead {
        .calendar-header {
            height: 35px;
            td {
                height: 54px;
                display: inline-flex;
                align-items: center;
            }
        }
    }
}

body.light-theme {
    
    .calendarIcon {
        background-image: url("../../assets/icons/calendar-icon-dark.png");
        height: 28px;
        width: 35px;
        background-repeat: no-repeat;
        background-size: 30px;
        background-position: center;
    }
    span.prevButton {
        background-image: url("../../assets/icons/cal-back-icon.png");
        filter: invert(100%) brightness(0);
        height: 28px;
        width: 35px;
        background-repeat: no-repeat;
        background-size: 30px;
        background-position: center;
        
    }
    span.nextButton {
        background-image: url("../../assets/icons/cal-next-icon.png");
        filter: invert(100%) brightness(0);
        height: 28px;
        width: 35px;
        background-repeat: no-repeat;
        background-size: 30px;
        background-position: center;
    }
    .label-month,
    .label-year {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: calc(1rem);
        line-height: 33px;
        color: $primary-text-color;
        cursor: pointer;
    }
    .emptySlot {
        background: #f5f8fa;
        border: 1px solid #eff3f6;
    }
    .dayDesktop {
        height: 104px;
        width: 104px;

        border: 1px solid #eff3f6;
        div.eventDataGroup {
            height: 100px;
            overflow: hidden;
            overflow-y: scroll;
            .eventDataDesktop {
                background: rgba(255, 224, 1, 0.1);
                border: 1px solid #ffe001;
                box-sizing: border-box;
                box-shadow: 0px 2px 3px rgba(20, 50, 80, 0.05);
                border-radius: 4px;
                font-family: "Inter";
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 12px;
                /* identical to box height, or 120% */
                letter-spacing: -0.1px;

                color: #000;
                &.invalidDate {
                    display: none !important;
                }
                a {
                    text-decoration: none;
                    color: #000;
                }
            }
        }
        div.datesDesktop {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            padding-left: 2px;
            padding-right: 10px;
            span {
                font-family: "Inter";
                font-style: normal;
                font-weight: normal;
                font-size: 0.75rem;
                line-height: 0.75rem;
                /* identical to box height, or 120% */
                letter-spacing: -0.1px;

                color: $primary-text-color;

                mix-blend-mode: normal;
                opacity: 0.4;
                &.current-dayDesktop {
                    background: $yellow-dark;
                    border-radius: 50%;
                    padding: 6px;
                    color: #000;
                    width: 20px;
                    height: 20px;
                    align-items: center;
                    display: inline-flex;
                }
            }
        }
    }
}
body.dark-theme {
    .calendarIcon {
        background-image: url("../../assets/icons/calendar-icon-dark.png");
        height: 28px;
        width: 35px;
        background-repeat: no-repeat;
        background-size: 30px;
        background-position: center;
    }
    span.prevButton {
        background-image: url("../../assets/icons/cal-back-icon.png");
        filter: invert(100%) brightness(8.5);
        height: 28px;
        width: 35px;
        background-repeat: no-repeat;
        background-size: 30px;
        background-position:center;
    }
    span.nextButton {
        background-image: url("../../assets/icons/cal-next-icon.png");
        filter: invert(100%) brightness(8.5);
        height: 28px;
        width: 35px;
        background-repeat: no-repeat;
        background-size: 30px;
        background-position: center;
    }
    .label-month,
    .label-year {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: calc(1rem);
        line-height: 33px;
        color: #fff;
        cursor: pointer;
    }
    .emptySlot {
        background: $dark-mode-bg;
        border: 1px solid $dark-mode-card;
    }
    .dayDesktop {
        height: 104px;
        width: 104px;

        border: 1px solid $dark-mode-bg;
        div.eventDataGroup {
            height: 100px;
            overflow: hidden;
            overflow-y: scroll;
            padding: 0.3rem;
            .eventDataDesktop {
                background: rgba(255, 224, 1, 0.1);
                border: 1px solid #ffe001;
                box-sizing: border-box;
                box-shadow: 0px 2px 3px rgba(20, 50, 80, 0.05);
                border-radius: 4px;
                font-family: "Inter";
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 12px;
                /* identical to box height, or 120% */
                letter-spacing: -0.1px;

                color: white;
                &.invalidDate {
                    display: none !important;
                }
                a {
                    text-decoration: none;
                    color: white;
                }
            }
        }
        div.datesDesktop {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            padding-left: 2px;
            padding-right: 10px;
            span {
                font-family: "Inter";
                font-style: normal;
                font-weight: normal;
                font-size: 0.75rem;
                line-height: 0.75rem;
                /* identical to box height, or 120% */
                letter-spacing: -0.1px;

                color: #fff;

                mix-blend-mode: normal;
                opacity: 0.4;
                &.current-dayDesktop {
                    background: $yellow-dark;
                    border-radius: 50%;
                    padding: 6px;
                    color: #000000;
                    width: 20px;
                    height: 20px;
                    align-items: center;
                    display: inline-flex;
                }
            }
        }
    }
}
