/* Custom grid start here */
.sprout-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, minmax(10rem, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 2.2rem;
  grid-column-gap: 2rem;
}


@media screen and (min-width: 520px){
  .sprout-grid {
    grid-template-columns: repeat(2, minmax(10rem, 1fr));
  }
}

@media screen and (min-width: 720px){
  .sprout-grid {
    grid-template-columns: repeat(3, minmax(10rem, 1fr));

    &.classroom-grid {
      grid-template-columns: repeat(2, minmax(10rem, 1fr));
    }
  }
}

@media screen and (min-width: 900px){
  .sprout-grid.classroom-grid {
    grid-template-columns: repeat(3, minmax(10rem, 1fr));
  }
}

@media screen and (min-width: 1200px){
  .sprout-grid {
    grid-template-columns: repeat(4, minmax(10rem, 1fr));
  }
}

@media screen and (min-width: 1500px){
  .sprout-grid.classroom-grid {
    grid-template-columns: repeat(4, minmax(10rem, 1fr));
  }
}

@media screen and (min-width: 2000px){
  .sprout-grid {
    grid-template-columns: repeat(5, minmax(10rem, 1fr));
  }
}
