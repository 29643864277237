@import "../../assets/scss/colors.scss";
.a-primary{
  padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
}
.text-fade{
  color: $text-fade-color
}
.activeButton{
  position: relative;
  z-index: 1045;
  background-color: #fff!important;
  padding:10px 36px !important;
}
.activeButton::after{
    content: url('../../assets/img/onboarding/line.png');
    right: -111px;
    position: absolute;
    top: 33%;
}
body.dark-theme{
  .sme-btn-link-white:focus, .sme-btn-link-white:hover {
    color: $primary-text-color;
    background-color: $dark-mode-card !important;
    border:none;
    text-decoration: underline;
   
  }
  .sme-btn-link-white:not(:disabled):not(.disabled).active,
  .sme-btn-link-white:not(:disabled):not(.disabled):active, 
  .show>.sme-btn-link-white.dropdown-toggle {
    color: $primary-text-color;
    background-color: $dark-mode-card !important;

    border:none;
   
  }
  .sme-btn-link-white:not(:disabled):not(.disabled).active:focus, 
  .sme-btn-link-white:not(:disabled):not(.disabled):active:focus, 
  .show>.sme-btn-link-white.dropdown-toggle:focus{
    color: $primary-text-color;
    background-color: $dark-mode-card !important;
    border:none;
    
  }
}
body.light-theme{
  .sme-btn-link-white:focus, .sme-btn-link-white:hover {
    color: $primary-text-color;
    background-color: #fff!important;
    border:none;
    text-decoration: underline;
   
  }
  .sme-btn-link-white:not(:disabled):not(.disabled).active,
  .sme-btn-link-white:not(:disabled):not(.disabled):active, 
  .show>.sme-btn-link-white.dropdown-toggle {
    color: $primary-text-color;
    background-color: #fff!important;
    border:none;
    box-shadow: 0 0 0 0.2rem #fff;
  }
  .sme-btn-link-white:not(:disabled):not(.disabled).active:focus, 
  .sme-btn-link-white:not(:disabled):not(.disabled):active:focus, 
  .show>.sme-btn-link-white.dropdown-toggle:focus{
    color: $primary-text-color;
    background-color: #fff!important;
    border:none;
    box-shadow: 0 0 0 0.2rem #fff;
  }
}
.walkthrough div.modal-content{
    background: transparent !important;
    border: none !important;
}
.walkthrough .card-text{
    font-size: 0.87rem !important;
    line-height: 1.625rem;
}
.buttonSpaceBetween{
  display:flex;
  justify-content: space-between;
}
.buttonAlignEnd{
  display:flex;
  justify-content: flex-end;
}
.card-position-left{
  top: -6rem;
  left:-1VW;
  // left:  calc(100% / 11);
}
.card-position-left .title{
  font-size: 1rem;
  font-weight:600
}
.card-position-left .active.card{
  width: 66.3%;
  padding:0
}
.h-200{
  max-height: 230px;
}
.carousel-card button, .carousel-card a{
  font-size: 0.875rem;
}
ol.sme-carousel-indicators {
    width: 40%;
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding-left: 0px;
  }
  
  ol.sme-carousel-indicators li {
    float: left;
    width: 8px;
    height: 10px;
    margin: 0;
    border-radius: 0;
    border: 0;
    border-radius: 50%;
    background: $carousel-in-active;
  }
  
  ol.sme-carousel-indicators li.active {
    background: $yellow-dark;
    width:28px;
    border-radius: 6px;
  }

  .carousel-card{
      display:none
  }
  .carousel-card.active{
    display:block
}
.walkthroughIcon{
    height: 40px;
}
.carousel-small-text{
    font-size: 0.6875rem !important;
}


@media (min-width: 1920px){
  .card-position-left{
    left:  -8VW;
  }
}

@media (min-width: 1300px) and (max-width: 1700px) {
  .card-position-left{
    left: -5VW;
  }
}
@media (min-width: 1700px) and (max-width: 1760px){
  .card-position-left{
    left: -8VW;
  }
}
.sme-d-flex{
  display: flex;
}
@media(max-width: 800px){
  .sme-d-flex{
    display: grid;
    div{
      margin-left: 1.5rem;
      padding-top: 1rem;
    }
  }
}
body.dark-theme{
  .sme-btn-link-white{
    background-color: inherit !important;
    color: white !important;
  }
}