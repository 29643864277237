@import "../../assets/scss/colors.scss";

.side-bar-sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}
body.dark-theme {
    .z-index-999{
        z-index:999;
    }
    .sidebarOl,
    .sidebarOl li > .btn-link {
        list-style: none;
        font-size: 0.875rem;
        text-decoration: none !important;
        border: none;
    }

    .sme-sidebar-item > .btn-link > img {
        filter: invert(71%) sepia(11%) saturate(516%) hue-rotate(187deg) brightness(96%) contrast(91%);
    }
    .sme-sidebar-header-item {
        padding: 20px 36px;
    }
    .sme-sidebar-header-item > .btn-link {
        color: #fff;
        font-weight: 600;
        padding: 0;
        border: none;
    }

    .sme-sidebar-item > .btn-link > img {
        width: 25px;
        height: 25px;
    }
    .sme-sidebar-item > .btn-link {
        color: $menu-normal;
        padding: 10px;
        width: 100%;
        justify-content: left;
        display: flex;
        line-height: 1.375rem;
        line-height: 26px;
    }

    .sme-sidebar-item > .btn-link:hover,
    .menu-active,
    .sme-sidebar-item > .btn-link:focus,
    .sme-sidebar-item > .btn.focus,
    .sme-sidebar-item > .btn:focus {
        background-color: $btn-primary-box-shadow;
        background: $yellow-dark;
        color: $menu-active;
        width: 100%;
        font-weight: 600;
        border-radius: 6px;
        border: none;
        box-shadow: none !important;
        outline: 0;
        transform: translateY(4px);
    }

    .sme-sidebar-item > .btn-link:hover > img,
    .menu-active.btn-link > img {
        filter: invert(0%) sepia(0%) saturate(100%) hue-rotate(87deg) brightness(0%) contrast(119%);
    }
    .menu-active.btn-link {
        color: $menu-active;
        font-weight: 600;
    }
    .menu-active.btn.focus,
    .menu-active.btn:focus {
        // box-shadow: 0 0 0 0.2rem $btn-primary-box-shadow;
        border: none;
    }
}
body.light-theme {
    .z-index-999{
        z-index:999;
    }
    .sidebarOl,
    .sidebarOl li > .btn-link {
        list-style: none;
        font-size: 0.875rem;
        text-decoration: none !important;
        border: none;
    }

    .sme-sidebar-item > .btn-link > img {
        filter: invert(71%) sepia(11%) saturate(516%) hue-rotate(187deg) brightness(96%) contrast(91%);
    }
    .sme-sidebar-header-item {
        padding: 20px 36px;
    }
    .sme-sidebar-header-item > .btn-link {
        color: $menu-header;
        font-weight: 600;
        padding: 0;
        border: none;
    }

    .sme-sidebar-item > .btn-link > img {
        width: 25px;
        height: 25px;
    }
    .sme-sidebar-item > .btn-link {
        color: $menu-normal;
        padding: 10px;
        width: 100%;
        justify-content: left;
        display: flex;
        line-height: 1.375rem;
        line-height: 26px;
    }

    .sme-sidebar-item > .btn-link:hover,
    .menu-active,
    .sme-sidebar-item > .btn-link:focus,
    .sme-sidebar-item > .btn.focus,
    .sme-sidebar-item > .btn:focus {
        background-color: $btn-primary-box-shadow;
        background: $yellow-dark;
        color: $menu-active;
        width: 100%;
        font-weight: 600;
        border-radius: 6px;
        border: none;
        box-shadow: none !important;
        outline: 0;
        transform: translateY(4px);
    }

    .sme-sidebar-item > .btn-link:hover > img,
    .menu-active.btn-link > img {
        filter: invert(0%) sepia(0%) saturate(100%) hue-rotate(87deg) brightness(0%) contrast(119%);
    }
    .menu-active.btn-link {
        color: $menu-active;
        font-weight: 600;
    }
    .menu-active.btn.focus,
    .menu-active.btn:focus {
        // box-shadow: 0 0 0 0.2rem $btn-primary-box-shadow;
        border: none;
    }
}
.sidebarOl li.sme-sidebar-item {
    padding: 5px 12px 5px 36px;
}
.sidebar-logo {
    height: 15vh;
}
@media (min-width: 300px) and (max-width: 760px) {
    .sidebarOl{
        width: 85%;
    }
}
@media (min-width: 768px) and (max-width: 980px){
    .sidebarOl{
        width: 35%;
    }
}
