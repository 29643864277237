@import "../../../assets/scss/colors";
@media (min-width: "768px") and (max-width: "980px") {
  .StudentCourseDetail {
    .normalplayer {
      height: 360px !important;
    }
  }
  .paymentButton {
    position: absolute;
    right: 13px !important;
    font-size: 0.7rem !important;
    text-decoration: none;
    line-height: 33px;
    top: 1% !important;
  }
}
@media (max-width: "500px") {
  .StudentCourseDetail {
    .normalplayer {
      height: 250px !important;
    }
  }
}
@media (min-width: "300px") and (max-width: "980px") {
  .paymentButton {
    position: absolute;
    right: 13px !important;
    font-size: 0.7rem !important;
    text-decoration: none;
    line-height: 33px;
    top: 4% !important;
  }
  .transcript {
    font-size: 0.75rem;
  }
  .StudentCourseDetail {
    .tab-holder {
      padding-top: 20px;
      .nav-tabs {
        border: none;
        .nav-link {
          font-size: 0.95rem;
          border: none;
          padding: 0.7rem !important;
          &.active {
            font-weight: normal;
            padding: 0.7rem !important;
          }
          &:focus {
            outline: none;
          }
        }
        // .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
      }
    }
  }
  .classroom-mobile-menu {
    list-style: none;
  }
  body.dark-theme {
    .classroomMenuMobile,
    .close {
      filter: invert(100%) brightness(8.5);
    }


    .classroom-mobile-menu li {
      border-bottom: thin solid rgba(27, 27, 27, 0.1);
      padding: 17px 10px;
      font-weight: 500;
      color: #fff;
      &.active {
        color: #fff;
        &:after {
          content: url("../../../assets/icons/checkbox-active.png");
          position: absolute;
          right: 0;
        }
      }
    }
    .menuModal {
      .modal-dialog {
        margin: 0 !important;
        .modal-content {
          position: fixed;
          bottom: -4px;
          border: none;
          background: $dark-mode-card;
        }
      }
    }
  }
  body.light-theme {
    .classroom-mobile-menu li {
      border-bottom: thin solid rgba(27, 27, 27, 0.1);
      padding: 17px 10px;
      font-weight: 500;
      color: $text-color;
      &.active {
        color: $primary-text-color;
        &:after {
          content: url("../../../assets/icons/checkbox-active.png");
          position: absolute;
          right: 0;
        }
      }
    }
    .menuModal {
      .modal-dialog {
        margin: 0 !important;
        .modal-content {
          position: fixed;
          bottom: -4px;
          border: none;
        }
      }
    }
    .StudentCourseDetail {
      .tab-holder {
        // background:#fff;
        color: #fff;
        .nav-tabs {
          background: #fff;
          border: none;
          .nav-link {
            border: none;
            padding: 1.2rem;
            color: $text-color;
            &.active {
              border-bottom: 2px solid $yellow-dark;
              color: #000;
              font-weight: 600;
            }
            &:focus {
              outline: none;
            }
          }
          // .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
        }
        .tab-content {
          background: #fff;
          margin-top: 10px;
        }
      }
    }
  }
  .resource-holder {
    border-bottom: 1px solid #dfe3e8;
    padding: 0.875rem;
    span {
      font-weight: normal !important;
      font-size: 0.75rem !important;
      &:before {
        width: 4px;
        height: 4px;
        margin-left: -3%;
        position: absolute;
        border-radius: 50%;
        content: " ";
        background: #a4adc8;
      }
    }
  }
}

.paymentButton {
  position: absolute;
  right: 32px;
  font-size: 0.8rem;
  text-decoration: none;
  line-height: 33px;
  top: 4%;
}

body.dark-theme {
  .StudentCourseDetail {
    .normalplayer {
      height: 360px;
    }
    .tab-holder {
      background: $dark-mode-card;
      color: #fff;
      .nav-tabs {
        border: none;
        .nav-link {
          border: none;
          padding: 1.2rem;
          color: #fff;
          font-size: 0.875rem;
          line-height: 24px;
          &.active {
            border-bottom: 2px solid $yellow-dark;
            color: $yellow-dark;
            font-weight: 600;
            background: #080e29;
          }
          &:focus {
            outline: none;
          }
        }
        // .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
      }
    }
    .activeTab,
    .tabBtn:hover {
      background: #000;
      border: 1px solid #000;
    }

    .btn-outline-primary {
      padding: 0.4rem 1rem;

      &:hover {
        .img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(5%)
            saturate(20%) hue-rotate(230deg) brightness(108%) contrast(105%);
        }

        span {
          color: #fff !important;
        }
      }
    }

    .img {
      height: 1rem;
      margin-right: 0.6rem;
    }

    .nextPrevBtn {
      background: transparent;
      border: 1px solid $yellow-dark;
      color: $yellow-dark;
      min-height: 4rem;

      &:active,
      &:focus {
        outline: none;
      }

      &:disabled {
        opacity: 0.5;
      }

      &:active:not(:disabled),
      &:not(:disabled):hover {
        i {
          border: solid #ffffff;
          border-width: 0 2px 2px 0;
        }

        background: $yellow-dark;
        color: #fff;
      }
    }

    .anchorText {
      line-height: 3rem;
      padding: 0 2rem;
    }

    a:hover {
      text-decoration: none;
    }
  }
  .transcript {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    color: #fff;
    font-size: 0.85rem;
    line-height: 24px;
  }
  .overview {
    font-family: "Inter" !important;

    b {
      font-weight: 600;
      font-size: 0.975rem;
      line-height: 24px;
      color: #fff;
      span {
        font-weight: normal;
      }
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 0.85rem;
      line-height: 24px;
      color: #fff;
    }
  }
  .resource-holder {
    border-bottom: 1px solid #dfe3e8;
    padding: 1rem;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 12px;
      padding: 10px;
      /* or 443% */
      letter-spacing: -0.154px;

      color: #fff;
      &:before {
        width: 8px;
        height: 8px;
        margin-left: -3%;
        position: absolute;
        border-radius: 50%;
        content: " ";
        background: #a4adc8;
      }
    }
  }
  .classroom-btn {
    background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
    border: 1px solid #c4cdd5;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
    border-radius: 3px;
    padding: 5px 10px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 25px;
    height: 37px;
    cursor: pointer;
    /* identical to box height, or 143% */
    text-align: center;
    letter-spacing: -0.154px;

    color: #212b36;
    &:hover {
      color: #212b36;
    }
  }
  .whatsapp {
    color: #000!important;
  }
}
body.light-theme {
  .StudentCourseDetail {
    .normalplayer {
      height: 360px;
    }
    .tab-holder {
      // background:#fff;
      color: #fff;
      .nav-tabs {
        background: #fff;
        border: none;
        .nav-link {
          border: none;
          padding: 1.2rem;
          color: $text-color;
          font-size: 0.875rem;
          line-height: 24px;
          &.active {
            border-bottom: 2px solid $yellow-dark;
            color: #000;
            font-weight: 600;
          }
          &:focus {
            outline: none;
          }
        }
        // .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
      }
      .tab-content {
        background: #fff;
      }
    }
    .activeTab,
    .tabBtn:hover {
      background: #000;
      border: 1px solid #000;
    }

    .btn-outline-primary {
      padding: 0.4rem 1rem;

      &:hover {
        .img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(5%)
            saturate(20%) hue-rotate(230deg) brightness(108%) contrast(105%);
        }

        span {
          color: #fff !important;
        }
      }
    }

    .img {
      height: 1rem;
      margin-right: 0.6rem;
    }

    .nextPrevBtn {
      background: transparent;
      border: 1px solid $yellow-dark;
      color: $yellow-dark;
      min-height: 4rem;

      &:active,
      &:focus {
        outline: none;
      }

      &:disabled {
        opacity: 0.5;
      }

      &:active:not(:disabled),
      &:not(:disabled):hover {
        i {
          border: solid #ffffff;
          border-width: 0 2px 2px 0;
        }

        background: $yellow-dark;
        color: #fff;
      }
    }

    .anchorText {
      line-height: 3rem;
      padding: 0 2rem;
    }

    a:hover {
      text-decoration: none;
    }
  }
  .transcript {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    color: #26292d;
    font-size: 0.85rem;
    line-height: 24px;

    /* or 175% */

    color: #26292d;
  }
  .overview {
    font-family: "Inter" !important;

    b {
      font-weight: 600;
      font-size: 0.975rem;
      line-height: 24px;
      color: #0e0e0e;
      span {
        font-weight: normal;
      }
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 24px;
      color: #4b4c4f;
    }
  }
  .resource-holder {
    border-bottom: 1px solid #dfe3e8;
    padding: 1rem;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 12px;
      padding: 10px;
      /* or 443% */
      letter-spacing: -0.154px;

      color: $primary-text-color;
      &:before {
        width: 8px;
        height: 8px;
        margin-left: -4%;
        position: absolute;
        border-radius: 50%;
        content: " ";
        background: #a4adc8;
      }
    }
  }
  .classroom-btn {
    background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
    border: 1px solid #c4cdd5;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
    border-radius: 3px;
    padding: 5px 10px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 25px;
    height: 37px;
    cursor: pointer;
    /* identical to box height, or 143% */
    text-align: center;
    letter-spacing: -0.154px;

    color: #212b36;
    &:hover {
      color: #212b36;
    }
  }
}

.whatsapp {
  display: flex;
  background: #ffe001;
  box-shadow: 0px 6px 13px rgba(82, 223, 169, 0.132784);
  border-radius: 6px;
  font-size: 14px;
  align-items: center;
  height: 70%;
}

.whatsapp .text {
  line-height: 100%;
  text-align: left;
}
.whatsapp .fa-whatsapp {
  font-size: 30px;
  margin-right: 5px;
}