
.setPassword{
    .modal-title{
        font-family: "Inter";
    }
    .modal-body{
        .card-title{
            font-family: "Inter";
            font-size: 0.9rem;
            font-weight: normal;
        }
    }
    
}
body.dark-theme{
    .close{
        color: #fff
    }
}
