body.dark-theme,
body.dark-theme .App {
    color: #fff;
    background-color:  #080E29 !important;
    font-family: "Inter", sans-serif;
}

body.dark-theme .card {
    background: #0F1635 !important;
}
body.dark-theme .card-footer,
body.dark-theme .discussion-card-header, 
body.dark-theme .discussion-card-footer {
    background: #0F1635;
}

body.dark-theme {
    .card .card-body,
    .card-footer,
    .discussion-card,
    .likeBtn,
    .commentBtn,
    .likeBtn,
    .bg-white,
    .CourseItem > .card-body > .card-title,
    .CourseItem > .card-body > .courseName,
    .CourseItem > .card-body > .coursePriceTag,
    .progressBar > div > span .discussion-time,
    .pageTitle,
    #header #pageTitle,
    .discussion-title-bar >span,
    .sme-sidebar-header-item .btn-link{
        color: #fff;
    }
}
body.dark-theme .commentBtn::before,
body.dark-theme .likeBtn::before {
    filter: invert(100%) brightness(8.5);
}
