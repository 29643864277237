@import '../../assets/scss/colors.scss';

.pagination-band  {

    hr{
        display: block;
        position: absolute;
        top: 50%;
        width: 90%;
        border-top: 1px solid #DFE3ED;
    }
    
}
body.light-theme {
    .pagination-band  {

        hr{
            display: block;
            position: absolute;
            top: 50%;
            width: 90%;
            border-top: 1px solid #DFE3ED;
        }
        
    }
    .pagination {
        cursor: pointer;
        font-family: 'Inter';
        font-style: normal;
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 34px;

        /* identical to box height, or 189% */
        text-align: center;

        color: #000000;
        z-index: 2;
        background: $body-light-background;
        
    }
}
body.dark-theme {
    .pagination-band  {

        hr{
            display: block;
            position: absolute;
            top: 50%;
            width: 90%;
            border-top: 1px solid #fff;
        }
        
    }
    .pagination {
        cursor: pointer;
        font-family: 'Inter';
        font-style: normal;
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 34px;

        /* identical to box height, or 189% */
        text-align: center;
        color: #fff;
        z-index: 2;
        background: $dark-mode-bg;
    }
}
