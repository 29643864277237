@import "../../assets/scss/colors.scss";
body.dark-theme {
    .backbutton img {
        filter: invert(100%) brightness(8.5);
    }
    .sticky {
        position: fixed;
        background: $dark-mode-bg;
        top: 0;
        width: 100%;
        z-index: 999999;
        box-shadow: 1px 1px 5px $dark-mode-bg;
    }

    .sticky+.content {
        padding-top: 102px;
    }

}
body.light-theme {
    .sticky {
        background: #fff;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999999;
        box-shadow: 1px 1px 5px #aaaaaa
    }

    .sticky+.content {
        padding-top: 102px;
    }

}
#header {
    height: 15vh;
}
#header > div {
    display: flex;
    align-items: center;
}

#header > :last-child {
    display: flex;
    justify-content: flex-end;
}
#pageTitle {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 34px;
    color: $menu-header;
}
#header #appLogo {
    height: 2.8125rem;
    width: 2.8125rem;
    margin-left: 3vw;
    cursor: pointer;
}
#mobileMenu {
    height: 1.25rem;
    width: 1.25rem;
    margin-left: 3vw;
}
#header .icon {
    width: 1.5625rem;
    margin-right: 20px;
}

#header .avatar {
    width: 3.125rem !important;
    height: 3.125rem !important;
    margin-right: 20px;
    border-radius: 50%;
}
.backbutton {
    cursor: pointer;
}

@media (max-width: 769px){
    .dropdown-content {
        left: -250px !important;
        &:before{
            right: 71px !important;
        }
    }
    #header{
        position: fixed;
        width: 100%;
        z-index: 3;
        top: 0;
    }
    .sidebar  {
        position:fixed !important;
        top: 98px;
    }
    .notificationHeader{
      margin-top: 5rem;
    }

}
@media (min-width: 810px) and (max-width: 1024px) {
    body.dark-theme {
        .page-title-holder {
            margin-bottom: 1rem;
            #pageTitle {
                .page-title {
                    color: #fff;
                }
            }
            &.active {
                background: #fff;
            }
        }
    }
    body.light-theme {
        .page-title-holder {
            margin-bottom: 1rem;
            &.active {
                background: #fff;
            }
        }
    }
}
@media (min-width: 300px) and (max-width: 599px) {
     .sidebar  {
       top: 66px;
    }
    .notificationHeader{
        margin-top: 3rem;
    }
}

@media (min-width: 300px) and (max-width: 991px) {
    body.dark-theme {
        .page-title-holder {
            margin-bottom: 1rem;
            #pageTitle {
                .page-title {
                    color: #fff;
                }
            }
            &.active {
                background: transparent;
            }
        }
        #mobileMenu {
            display:block;
        }
        #mobileMenu,
        .backbutton img {
            filter: invert(100%) brightness(8.5);
        }
        #header {
            height: 10vh;
            background: $dark-mode-bg;
            display: flex !important;
            justify-content: space-between !important;
            box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.05);
        }
        #logoHolder,
        #iconHolder {
            width: 50%;
        }
        .header a {
            float: none;
            display: block;
            text-align: left;
        }

        .header-right {
            float: none;
        }
        .sidebar  {
            background: $dark-mode-card;
            ol{
                background: $dark-mode-card;
                box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.05);
            }
            position: absolute;
            z-index: 1;
           left: 0;
           right: 0;

        }
    }
    body.light-theme {
        .page-title-holder {
            margin-bottom: 1rem;
            &.active {
                background: #fff;
            }
        }

        #header {
            height: 10vh;
            background: #fff;
            display: flex !important;
            justify-content: space-between !important;
            box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.05);
        }
        #logoHolder,
        #iconHolder {
            width: 50%;
        }
        .header a {
            float: none;
            display: block;
            text-align: left;
        }

        .header-right {
            float: none;
        }
        .sidebar  {
            background: #f5f7fd;
            ol{
                background: #f5f7fd;
                box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.05);
            }
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;

        }
    }
}


