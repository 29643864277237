@import "../../assets/scss/colors";
.CourseItemLink {
    text-decoration: none !important;
    &.disabled{
        pointer-events: none;
    }
}
.CourseItemLink .card-body {
    border-radius: 6px;
}

.courseName {
    white-space: normal;
    font-size: 1rem;
    font-weight: normal;
    color: #0e0e0e;
    word-wrap: break-word;
    line-height: 20px;
}

.coursePriceTag {
    white-space: normal;
    font-size: 1rem;
    font-weight: 600;
    color: #0e0e0e;
    line-height: 20px;
}
.max-line{
    
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-box-orient: vertical;
}
.max-line-2{
    -webkit-line-clamp: 2;
}
.CourseItem img {
    min-height: 10rem;
    max-height: 10.1rem;
    object-fit: cover;
    object-position: center;
    width: 100%;
}
.CourseItem > .card-body > .card-title {
    font-size: 0.75rem !important;
    color: $text-color;
    line-height: 1.375rem;
    font-weight: normal;
    word-break: break-word;
    white-space: normal;
}

// .btn:not(.no-lift),
// .lift {
//     transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
// }
// .btn:not(.no-lift):hover,
// .lift:hover {
//     box-shadow: 0 0.625rem 0.875rem -0.3125rem rgba(0, 0, 0, 0.3);
// }
@media (min-width: 320px) and (max-width: 980px) {
    .CourseItemLink {
        text-decoration: none !important;
        .addBtnArrow{
            &::after{
                content: '' !important;
            }
        }
    }
    .courseName {
        font-size: 0.75rem;
        min-height: 38px;
    }
    .CourseItem > .card-body > .card-title {
        display: none;
        max-height: 50px;
        overflow-x: scroll;
    }
}
body.dark-theme{
    .CourseItem > .card-body{
        background: $dark-mode-card;
    }
}
body.light-theme{
    .CourseItem > .card-body{
        background: #fff;
    }
}
