@import "../../assets/scss/colors.scss";
body.dark-theme {
    .allEventLink {
        font-family: "SF Pro Display";
        font-style: normal;
        font-weight: normal;
        font-size: 0.8125rem;
        line-height: 16px;

        /* identical to box height */
        letter-spacing: 0.874624px;
        text-transform: uppercase;

        color: #fff;
    }
    .upcomingEventTitle {
        h3 {
            font-family: SF Pro Display;
            font-style: normal;
            font-weight: 600;
            font-size: 1.1875rem;
            line-height: 23px;
            letter-spacing: 0.437312px;

            color: #fff;
        }
        p {
            font-family: "SF Pro Display";
            font-style: normal;
            font-weight: normal;
            font-size: 0.9375rem;
            line-height: 26px;

            /* identical to box height, or 171% */
            letter-spacing: 0.437312px;
        }
    }
    .calendar {
        background: $dark-mode-bg;
        .eventHeader {
            div,
            a,
            p {
                color: #fff;
            }
        }
        div.col-md-4 {
            .single-event-card {
                .card-body {
                    border: none;
                    color: #fff;
                    .card-title {
                        color: #fff;
                    }
                }
            }
        }
    }
    .eventCardDetails {
        .card-body {
            .card-title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;

                /* identical to box height */

                color: #fff;
                    /* identical to box height, or 171% */
            }
                p {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.875rem;
                    line-height: 24px;
                }
          
        }
    }
}
body.light-theme {
    .allEventLink {
        font-family: "SF Pro Display";
        font-style: normal;
        font-weight: normal;
        font-size: 0.8125rem;
        line-height: 16px;

        /* identical to box height */
        letter-spacing: 0.874624px;
        text-transform: uppercase;

        color: #4b4c4f;
    }
    .upcomingEventTitle {
        h3 {
            font-family: "SF Pro Display";
            font-style: normal;
            font-weight: 600;
            font-size: 1.1875rem;
            line-height: 23px;
            letter-spacing: 0.437312px;

            color: #171725;
        }
        p {
            font-family: "SF Pro Display";
            font-style: normal;
            font-weight: normal;
            font-size: 0.9375rem;
            line-height: 26px;

            /* identical to box height, or 171% */
            letter-spacing: 0.437312px;
        }
    }
    .calendar {
        background: #fff;
        .card {
            .card-body {
                color: #fff;
                border: "1.09328px solid #F1F1F5";
            }
        }
    }
    .eventCardDetails {
        .card-body {
            .card-title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;

                /* identical to box height */

                color: #171725;
                    /* identical to box height, or 171% */
            }
                p {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.875rem;
                    line-height: 24px;
                    font-family: Inter;
                    color: #4B4C4F;
                }
          
        }
    }
}

.eventAction {
    .card-body {
        display: flex;

        justify-content: space-between;
        flex-direction: column;
        .card-text p {
            font-weight: normal;
            font-size: 0.75rem;
            color: #4b4c4f;
        }
    }
}
