@import "../../assets/scss/colors.scss";

body.light-theme {
    .dropbtn {
        // padding: 16px;
        font-size: 1.2rem;
        color: #000;
        border: none;
        cursor: pointer;
        &.active {
            &::after {
                content: " ";
                border-radius: 50%;
                background: $yellow-dark;
                width: 10px;
                height: 10px;
                position: absolute;
                border: 8px;
                right: 14px;
                top: -3px;
            }
        }
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }
    .dc, .dcs{
        display:none;
    }
    .dropdown-content-mobile {
        display: none;
        margin-top: 10rem !important;
        position: absolute;
        top: -5rem;
        width: 100vw !important;
        right: 0;
        /* left: 0; */
        height: 100vh;
        div.inner {
            height: 250px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                background: $dark-mode-bg;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $yellow-dark;
                border-radius: 30px;
                border: none;
            }
        }
    }
    .dropdown-content {
        display: none;
        position: absolute;
        right: 0;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        width: max-content;
        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            position: absolute;

            border-width: 8px;
            border-style: solid;
            border-color: transparent transparent #fff transparent;
            right: 13px;

            top: -15px;
        }
        div.inner {
            height: 250px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }

            &::-webkit-scrollbar-thumb {
                background-color: #00000094;
                border-radius: 30px;
                border: none;
            }
        }
        a {
            color: black;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        div.notification-item {
            .notificationDetails {
                p {
                    font-size: 0.9rem;
                }
                p.time {
                    font-size: 0.7rem;
                   
                }
            }
        }
    }

    .dropdown-content-active {
        display: block;
    }
    .dropdown:hover .dropbtn {
        background-color: transparent;
    }
}
body.dark-theme {
    .callOut {
        color: black !important;
    }
    .dropbtn {
        font-size: 1.2rem;
        color: white;
        border: none;
        cursor: pointer;
        &.active {
            &::after {
                content: " ";
                border-radius: 50%;
                background: $yellow-dark;
                width: 10px;
                height: 10px;
                position: absolute;
                border: 8px;
                right: 14px;
                top: -3px;
            }
        }
    }
    .dc, .dcs{
        display:none;
    }
    .dropdown {
        position: relative;
        display: inline-block;
    }
    .dropdown-content-mobile {
        display: none;
        margin-top: 10rem !important;
        position: absolute;
        top: -5rem;
        width: 100vw !important;
        right: 0;
        /* left: 0; */
        height: 100vh;
        div.inner {
            height: 250px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                background: $dark-mode-bg;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $yellow-dark;
                border-radius: 30px;
                border: none;
            }
        }
    }
    .dropdown-content {
        display: none;
        position: absolute;
        right: 0;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        width: max-content;
        div.inner {
            height: 250px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                background: $dark-mode-bg;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $yellow-dark;
                border-radius: 30px;
                border: none;
            }
        }
        a {
            color: black;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            position: absolute;

            border-width: 8px;
            border-style: solid;
            border-color: transparent transparent $dark-mode-card transparent;
            right: 13px;

            top: -15px;
        }

        div.notification-item {
            .notificationDetails {
                p {
                    font-size: 0.6rem;
                }
                p.time {
                    font-size: 0.6rem;
                    
                }
            }
        }
    }

    .dropdown-content-active {
        display: block;
    }
    .dropdown:hover .dropbtn {
        background-color: transparent;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {

    body.dark-theme {
    .dropdown-content-mobile {
        top: -2rem;
    }
}
}