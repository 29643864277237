.pricing {
    
    
    .sec-2 {
     
      .no-border{
        border: none !important;
      }
      article {
        background-image: url("../../../assets/img/pricing/yellow-bg.png"), linear-gradient(#ffe001, #ffe001);
        border-radius: 1rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position-y: top;
        h2 {
          padding-bottom: 1rem;
          border-bottom: 1px solid #0e0e0e;
        }
        .size-14-px {
          font-size: 0.875rem !important;
        }
        button#makeEnquiry {
          border: none;
          text-decoration: underline;
          &:hover {
            background: transparent;
            border: none;
            text-decoration: underline;
            outline: none !important;
          }
          &:focus{
            outline:none !important;
          }
        }
      }
  
      .yellow-price-card {
        cursor: pointer;
        button#makeEnquiry {
          color: black !important;
          background: transparent;
          
        }
        .sme-btn-dark,.sme-btn-dark:disabled{
            background-color: #0e0e0e;
            border-color: #0e0e0e;
            color: #ffe001!important;
            transition: color 1s ease-in-out;
          }
      }
      .grey-price-card {
        cursor: pointer;
        background-image: url('../../../assets/img/pricing/bg-grey-1.png'), linear-gradient(#cbd5de, #cbd5de) !important;
        button#makeEnquiry {
          color: black !important;
          background: transparent;
          
        }
        .sme-btn-dark,.sme-btn-dark:disabled{
            background-color: #0e0e0e;
            border-color: #0e0e0e;
            color: #ffe001!important;
            transition: color 1s ease-in-out;
          }
      }
      .black-price-card {
        cursor: pointer;
        background-image: url('../../../assets/img/pricing/black-card.png'),linear-gradient(#0e0e0e, #0e0e0e) !important;
        color: white;
        button#makeEnquiry {
          color: white !important;
          background: transparent;
         
        }
      }
    }
  }
  
  