@import "../../../assets/scss/colors.scss";

@media (max-width: 1000px) {
    .alert-heading {
        font-size: 1.2rem;
    }
    .alert-content {
        font-size: 0.9rem;
    }
    .alert-action{
        font-size: 0.9rem;
    }
    .btn-outline-warning {
        font-size: 0.9rem;
    }
   
    body.dark-theme {
        .btn-outline-warning{
            border:  0.68px solid #F2F2F2 !important;
        }
        .alert-warning {
            color: #fff !important;
            background: #191f39 !important;
            border:  0.68px solid #F2F2F2 !important;
            .close {
                span {
                    filter: invert(100%) brightness(8.5);
                }
            }
        }
    }
}
body.dark-theme {
    .btn-outline-warning{
        border:  0.68px solid #F2F2F2 !important;
        color: #fff;
        font-family: "Inter" ;
    }
    .alert-warning {
        color: #fff !important;
        background: #191f39 !important;
        border:  0.68px solid #F2F2F2 !important;
        
    }
    .btn-outline-warning:hover {
        color: #212529;
        background-color: $yellow-dark;
        border: 1px solid transparent !important;
    }
}
body.light-theme {
   
    .alert-warning {
        color: #000 !important;
        background: #fff !important;
        border: 1px solid #4b4c4f57 !important ;
    }
    .btn-outline-warning {
        color: #000 !important;
        border-color: #000;
    }
    .btn-outline-warning:hover {
        color: #212529 !important;
        background-color: $yellow-dark !important;
        border: 1px solid transparent !important;
    }
}
