@import "../../assets/fonts/fonts.scss";
@import "../../assets/scss/colors.scss";
.discussion-card{
    font-family: 'Inter';
    font-size:0.875rem;
    line-height: 14px;
}
.discussion-card{
    font-family: 'Inter';
    font-size: 0.875rem;
    line-height: 14px;
    font-weight: normal;
    color: $menu-header;
    line-height: 20px;
}
.avatar {
    width: 24px !important;
    height: 24px !important;
    border-radius: 50%;
}
.discussion-card-header, .discussion-card-footer{
    // box-shadow: 0px 0.978112px 0px rgba(82, 92, 229, 0.0662424);
    border: none;
    background: #fff;
}

.likeBtn, .commentBtn{
    padding:10px 10px;
    font-size: 0.875rem;
    line-height: 24px;
    font-family: 'SF Pro Display';
    color: $discussion-action-color;
    letter-spacing: 0.2px;
    margin-right: 1rem !important;
    cursor: pointer;
    background: transparent;
    border: none;
}
body.dark-theme{
    .fill-red img{
        filter: invert(18%) sepia(99%) saturate(7474%) hue-rotate(3deg) brightness(101%) contrast(116%);
    }
    .discussion-card-header b{
        font-weight: 600;
        line-height: 19px;
        font-size: 0.875rem;
        line-height: 17px;
        color: #fff;
    }
    .comments li{
    color: white;
  }
}
body.light-theme{
    .comments li{
        color: $menu-active;
      }
    .fill-red {
        filter: invert(18%) sepia(99%) saturate(7474%) hue-rotate(3deg) brightness(101%) contrast(116%);
    }
    .discussion-card-header b{
        font-weight: 600;
        line-height: 19px;
        font-size: 0.875rem;
        line-height: 17px;
        color: #000;
    }
}

.likeBtn:hover, .commentBtn:hover {
    background-color: #b7b2b21f;
    border-radius: 10px;
}
.likeBtn:focus, .commentBtn:focus{
outline:0;
}
.likeBtn>img, .commentBtn>img{
    width: 1.375rem;
    transform: scale(.7);
    height: 1.1875rem;
   
}

.discussion-time{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: $discussion-time;
}
.discussion-title-bar>span{
    font-style: normal;
    font-weight: 300;

    line-height: 20px;
    letter-spacing: 0.3px;
    color: $text-color;
}
.discussion-title-bar>a{
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: $text-color;
}
.discussion-card-header .categories{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 14px;
    text-align: center;
    align-items: center;
    display: grid;
}

.noCourseImg {
    width: 7.5rem;
    margin-bottom: 3rem;
  }
  
  
.comment-holder{
    // min-height: 100px;
    background:#f5f7fd5e;
}
.comment-holder input{
    border:none;
}
.comment-holder input::placeholder{
    padding-left:1rem;
    padding-right:1rem;
}
.comment-holder input:hover,
.comment-holder:active,
 .comment-holder:focus{
    border:none;
}
  .comments{
    list-style: none;
    max-height:150px;
    overflow:auto;
  }
  .comments li{
    font-size: 0.875rem;
      min-height: 2rem;
      padding: 10px 15px;
      border-bottom: 1px solid #ccc3;
  }